<template>
  <ul class="breadcrumb">
    <li v-for="item in items" v-bind:key="item.route">
      <router-link
        class="breadcrumb-item"
        :to="{ name: item.route }"
      >
        {{ item.label }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    items: {
      required: true,
      type: Array,
    }
  }
}
</script>

<style scoped>
.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumb li {
  display: inline-table;
  margin: 0 8px;
  font-size: 10pt;
  position: relative;
}

.breadcrumb li:nth-child(even)::before {
  content: "/";
  color: #999;
  position: absolute;
  left: -10px;
}

.breadcrumb li a {
  color: #333;
  transition: all .3s;
}

.breadcrumb li a.router-link-active {
  color: #999;
}

.breadcrumb li a:hover {
  color: #FF3333;
  text-decoration: underline;
}
</style>
