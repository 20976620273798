<template>
  <div id="dispatched-webhooks-chart"></div>
</template>

<script>
import { Chart } from "frappe-charts/dist/frappe-charts.min.esm"
export default {
  name: 'DispatchedEventsChart.vue',
  props: ['webhooks', 'height'],
  mounted () {
    this.updateChart()
  },
  methods: {
    updateChart () {
      const labels = this.webhooks.map(e => e.date)
      new Chart( "#dispatched-webhooks-chart", {
        data: {
          labels: labels,

          datasets: [
            {
              name: "Disparados",
              chartType: 'line',
              values: this.webhooks.map(e => e.total)
            },
            {
              name: "Falhos",
              chartType: 'line',
              values: this.webhooks.map(e => e.failed)
            },
          ],
        },

        title: "Chamadas de Webhook",
        type: 'line',
        height: typeof this.height !== 'undefined' ? this.height : 300,
        colors: ['blue', 'red'],
      });
    }
  }
}
</script>
