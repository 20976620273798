<template>
  <div class="modal-container">
    <div class="modal-backdrop" v-show="show" @click="dismiss"></div>
    <div class="modal">
      <div class="modal-content">
        <div class="modal-title">
          <h4 v-bind:class="typeof titleCenter != 'undefined' ? 'text-center' : ''">{{ title }}</h4>
          <a href="#" class="modal-close" @click.prevent="dismiss">&times;</a>
        </div>

        <div class="modal-body">
          <slot name="body"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['title', 'titleCenter'],
  data: () => ({
    show: true,
  }),
  methods: {
    dismiss(e) {
      if (!e.target.className.includes('modal-backdrop') && !e.target.className.includes('modal-close')) {
        return
      }

      this.$emit('dismiss')
    }
  }
}
</script>

<style scoped>
  .modal-backdrop, .modal-container {
    display: block;
    height: 100vh;
    position: fixed !important;
    width: 100vw;
    left: 0;
    top: 0;
    cursor: pointer;
    overflow: hidden;
  }

  .modal-container {
    z-index: 9001;
  }

  .modal-backdrop {
    background: rgba(0, 0, 0, .6);
    transition: none;
    z-index: -1;
  }

  .modal {
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 20px #333;
    cursor: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-height: 60vh;
    max-width: 80vw;
    overflow-y: scroll;
    padding: 8px;
    position: absolute;
    width: 600px;
  }

  .modal h4 {
    margin: 8px auto;
  }

  .modal-close {
    position: absolute;
    font-weight: bolder;
    right: 6px;
    top: 4px;
    font-size: 17px;
    padding: 10px;
  }

  .modal-body {
    padding: 16px;
    border-top: 1px solid #eee;
  }
</style>
