<template>
  <div class="left-bar">
    <div class="header">
      <router-link to="/dashboard">
        <img src="@/assets/img/logo.png" alt="pede.ai - Delivery de tudo" id="logo" />
      </router-link>

      <div class="basic-user-info">
        <p>{{ user.social_reason }}</p>
        <small>{{ user.email }}</small>
      </div>
    </div>

    <div class="left-bar-menu">
      <router-link
        class="left-bar-button"
        v-for="(item, index) in items"
        v-bind:key="index"
        :to="{ name: item.to, params: item.params }"
      >
        <i class="fa" v-bind:class="item.icon"></i>
        {{ item.label }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LeftBar',
  computed: {
    ...mapGetters({ user: 'getUser' }),
  },
  data: () => ({
    items: [
      {
        label: 'Dashboard',
        icon: 'fa-tachometer-alt',
        to: 'Dashboard'
      },
      {
        label: 'Eventos',
        icon: 'fa-list-alt',
        to: 'Events'
      },
      {
        label: 'Webhooks',
        icon: 'fa-network-wired',
        to: 'Webhooks'
      },
      {
        label: 'Configurações',
        icon: 'fa-cog',
        to: 'Settings'
      },
    ]
  }),
}
</script>

<style scoped>
.left-bar {
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 0 10px #CCCCCC;
  font-family: Nunito;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

.left-bar .header {
  padding: 8px;
  text-align: center;
}

.left-bar .basic-user-info {
  margin: 16px 0;
  min-height: 40px;
}

.left-bar .basic-user-info p {
  color: #333;
  font-family: NunitoBold;
  font-size: 10pt;
  margin: 0;
  padding: 0;
}

.left-bar .basic-user-info small {
  color: #999;
  font-size: 9pt;
  margin: 0;
  padding: 0;
}

.left-bar-menu {
  position: relative;
  padding: 0 8px;
}

.left-bar-button {
  border-radius: 5px;
  display: block;
  text-align: left;
  margin: 8px 0;
  padding: 12px 16px;
  position: relative;
  transition: background .3s;
}

.left-bar-button .fa {
  font-size: 13px;
  margin-right: 8px;
}

.left-bar-button:first-child {
  margin-top: 0;
}

.left-bar-button:last-child {
  margin-bottom: 0;
}

.left-bar-button:hover {
  background: #DFDFDF;
}

.left-bar-button.router-link-active {
  background: #FF3333;
  color: #FFFFFF;
}

@media screen and (max-width: 860px) {
  .left-bar {
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 50px;
  }
}
</style>
