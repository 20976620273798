<template>
  <div :class="classes">
    <div class="info-card-count">{{ count }}</div>
    <div class="info-card-icon">
      <i :class="`fa fa-${icon}`"></i>
    </div>
    <div class="info-card-text">
      {{ text }}
      <slot name="text" />
    </div>
    <div class="info-card-loading-indicator" v-if="typeof loading !== 'undefined' && loading"></div>
  </div>
</template>

<script>
export default {
  name: 'InfoCard',
  props: {
    count: {
      required: true,
    },
    icon: {
      required: true,
      type: String,
    },
    color: {
      required: false,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },
    loading: {
      required: false,
    }
  },
  computed: {
    classes () {
      return {
        'info-card': true,
        'info-card-green': this.color === 'green',
        'info-card-orange': this.color === 'orange',
        'info-card-blue': this.color === 'blue',
        'info-card-white': this.color === 'white',
        'info-card-yellow': this.color === 'yellow',
        'info-card-black': this.color === 'black',
        'info-card-red': this.color === 'red',
        'info-card-loading': typeof this.loading !== 'undefined' && this.loading,
      }
    },
  },
}
</script>

<style scoped>
  .info-card {
    background: #333;
    box-shadow: 0 0 10px #c0c0c0;
    border-radius: 5px;
    color: #ffffff;
    overflow: hidden;
    min-height: 90px;
    padding: 8px;
    position: relative;
    transition: background .3s;
  }

  .info-card-red {
    background: #ff3333;
  }

  .info-card-green {
    background: #12A454;
  }

  .info-card-orange {
    background: #FF872C;
  }

  .info-card-yellow {
    background: #EBC600;
  }

  .info-card-blue {
    background: #0099cc;
  }

  .info-card-white {
    background: #ffffff;
    color: #333333;
  }

  .info-card-black {
    background: #333333;
  }

  .info-card-count {
    font-family: NunitoBold;
    font-size: 36px;
  }

  .info-card-text {
    max-width: 55%;
  }

  .info-card-icon {
    opacity: .4;
    position: absolute;
    right: -15px;
    top: -15px;
  }

  .info-card-icon .fa {
    font-size: 100px;
    z-index: -1;
  }

  .info-card-loading-indicator {
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: red;
    bottom: 0;
    content: "";
    height: 5px;
    left: 0;
    opacity: .4;
    position: absolute;
    width: 25px;
  }

  .info-card-loading {
    background: #ffffff;
    color: #c0c0c0 !important;
  }
</style>
