import Vue from 'vue'
import VueRouter from 'vue-router'
import authMiddleware from '@/middlewares/authMiddleware'

// PAGES
import Login from '@/pages/Login.vue'
import Template from '@/pages/auth/Template.vue'
import Dashboard from '@/pages/auth/Dashboard.vue'
import Webhooks from '@/pages/auth/Webhooks.vue'
import Events from '@/pages/auth/Events.vue'
import Settings from '@/pages/auth/Settings.vue'
import Profile from '@/pages/auth/Profile.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/dashboard',
    component: Template,
    meta: {
        bread: [{ label: 'Dashboard', route: 'Dashboard' }],
        requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          bread: [
            { label: 'Dashboard', route: 'Dashboard' },
          ],
          requiresAuth: true
        }
      },
      {
        path: '/webhooks',
        name: 'Webhooks',
        component: Webhooks,
        meta: {
          bread: [
            { label: 'Dashboard', route: 'Dashboard' },
            { label: 'Webhooks', route: 'Webhooks' },
          ],
          requiresAuth: true
        }
      },
      {
        path: '/events',
        name: 'Events',
        component: Events,
        meta: {
          bread: [
            { label: 'Dashboard', route: 'Dashboard' },
            { label: 'Eventos', route: 'Events' },
          ],
          requiresAuth: true
        }
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
          bread: [
            { label: 'Dashboard', route: 'Dashboard' },
            { label: 'Configurações', route: 'Settings' },
          ],
          requiresAuth: true
        }
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
          bread: [
            { label: 'Dashboard', route: 'Dashboard' },
            { label: 'Perfil', route: 'Profile' },
          ],
          requiresAuth: true
        }
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(authMiddleware)

export default router
