<template>
  <div id="dispatched-events-chart"></div>
</template>

<script>
import { Chart } from "frappe-charts/dist/frappe-charts.min.esm"
export default {
  name: 'DispatchedEventsChart.vue',
  props: ['events', 'height'],
  mounted () {
    this.updateChart()
  },
  methods: {
    updateChart () {
      const labels = this.events.map(e => e.date)
      new Chart( "#dispatched-events-chart", {
        data: {
          labels: labels,

          datasets: [
            {
              name: "Disparados",
              chartType: 'line',
              values: this.events.map(e => e.total)
            },
            {
              name: "Reconhecidos",
              chartType: 'line',
              values: this.events.map(e => e.confirmed)
            },
          ],
        },

        title: "Eventos",
        type: 'line',
        height: typeof this.height !== 'undefined' ? this.height : 300,
        colors: ['blue', 'green'],

        // tooltipOptions: {
        //   formatTooltipX: d => (d + ''),
        //   formatTooltipY: d => d,
        // }
      });
    }
  }
}
</script>
