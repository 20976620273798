<template>
  <div id="page-profile">
    <div class="flex">
      <div class="flex-item" id="fi-settings">
        <panel title="Dados cadastrais">
          <profile-form
            v-if="editing"
            @cancel="toggleEdit"
            @success="toggleEdit"
          />
          <template v-else>
            <div class="text-right">
              <button class="btn btn-info" @click="toggleEdit"><i class="fa fa-edit"></i></button>
            </div>
            <div class="flex">
              <div class="flex-item text-left">
                <p><strong>Usuário:</strong></p>
              </div>
              <div class="flex-item text-left">
                <p>{{ user.username }}</p>
              </div>
            </div>

            <div class="flex">
              <div class="flex-item text-left">
                <p><strong>Razão Social:</strong></p>
              </div>
              <div class="flex-item text-left">
                <p>{{ user.social_reason }}</p>
              </div>
            </div>

            <div class="flex">
              <div class="flex-item text-left">
                <p><strong>Nome Fantasia:</strong></p>
              </div>
              <div class="flex-item text-left">
                <p>{{ user.fantasy_name }}</p>
              </div>
            </div>

            <div class="flex">
              <div class="flex-item text-left">
                <p><strong>CNPJ:</strong></p>
              </div>
              <div class="flex-item text-left">
                <p>{{ user.cnpj | VMask('##.###.###/####-##') }}</p>
              </div>
            </div>

            <div class="flex">
              <div class="flex-item text-left">
                <p><strong>Representante:</strong></p>
              </div>
              <div class="flex-item text-left">
                <p>
                  {{ user.agent_name }}
                  <br />
                  <small>CPF: {{ user.agent_cpf | VMask('###.###.###-##') }}</small>
                </p>
              </div>
            </div>

            <div class="flex">
              <div class="flex-item text-left">
                <p><strong>Contato:</strong></p>
              </div>
              <div class="flex-item text-left">
                <p>{{ user.phone | VMask('(##) #####-####') }}</p>
              </div>
            </div>

            <div class="flex">
              <div class="flex-item text-left">
                <p><strong>E-mail:</strong></p>
              </div>
              <div class="flex-item text-left">
                <p>{{ user.email }}</p>
              </div>
            </div>
          </template>
        </panel>
      </div>

      <div class="flex-item">
        <panel title="Alteração de senha">
          <UpdatePasswordForm @success="onPasswordUpdated" />
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Panel from '@/components/general/Panel.vue'
import UpdatePasswordForm from '@/components/forms/UpdatePasswordForm.vue'
import ProfileForm from '../../components/forms/ProfileForm.vue';

export default {
  name: 'Profile',
  components: {
    Panel,
    UpdatePasswordForm,
    ProfileForm,
  },
  data: () => ({
    editing: false,
  }),
  computed: {
    ...mapGetters({ user: 'getUser', settings: 'getSettings' })
  },
  methods: {
    onPasswordUpdated ({ message }) {
      this.$notify({ message })
    },
    toggleEdit () {
      this.editing = !this.editing
    }
  }
}
</script>
