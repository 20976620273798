<template>
  <a :href="href" class="btn button-back media-relative"><i class="fas fa-arrow-left"></i> Voltar</a>
</template>

<script>
export default {
  name: 'ButtonBack',
  props: {
    href: {
      required: true,
    }
  }
}
</script>

<style scoped>
.button-back {
  color: #333333 !important;
  font-family: NunitoBold;
  text-align: left;
  position: absolute;
}

.fas {
  margin-right: 15px;
}
</style>
