<template>
  <div id="profile-form-container">
    <form action="" @submit.prevent="update">
      <div class="form-group m-0 flex">
        <div class="flex-item text-left">
          <p><strong>Usuário:</strong></p>
        </div>
        <div class="flex-item">
          <input type="text" required v-model="user.username">
        </div>
      </div>

      <div class="form-group m-0 flex">
        <div class="flex-item text-left">
          <p><strong>Contato:</strong></p>
        </div>
        <div class="flex-item">
          <input type="text" required v-model="user.phone" v-mask="'(##) #####-####'">
        </div>
      </div>

      <div class="form-group m-0 flex">
        <div class="flex-item text-left">
          <p><strong>E-mail:</strong></p>
        </div>

        <div class="flex-item">
          <input type="email" required v-model="user.email">
        </div>
      </div>

      <div class="form-group text-right">
        <br>
        <button class="btn btn-default mr-5" type="button" @click="$emit('cancel')">
          <i class="fa fa-times"></i> Fechar
        </button>

        <button
          v-if="Object.keys(user).length"
          class="btn btn-primary"
          type="submit"
          :disabled="loading || !user.email.length || !user.phone.length"
        >
          Salvar alterações
          <i class="fa fa-spin fa-spinner" v-if="loading"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Auth from '@/services/Auth'

export default {
  name: 'ProfileForm',
  data: () => ({
    loading: false,
    user: {},
  }),
  computed: {
    ...mapGetters({ userData: 'getUser' }),
  },
  mounted () {
    this.user = { ...this.userData }
  },
  methods: {
    ...mapMutations([ 'setUser' ]),
    async update () {
      this.loading = true
      const result = await Auth.profileUpdate({ ...this.user })
      this.loading = false

      this.$notify({
        message: result.message,
        type: result.ok ? 'success' : 'error',
      })

      if (!result.ok) {
        return
      }

      this.setUser(result.user)
      this.$emit('success', { ...result })
    },
  }
}
</script>
