import api from './api'

const Auth = {
  login: async data => {
    return api.post('auth/login', { ...data })
      .then(r => ({
        ok: true,
        user: r.data.user,
        token: r.data.token,
        settings: r.data.settings
      }))
      .catch(e => ({
        ok: false,
        message: e.response ? e.response.data.message || 'Não foi possível fazer login' : ''
      }))
  },
  user: async () => {
    return api.get('/user')
      .then(r => ({
        ok: true,
        user: r.data.user,
      }))
      .catch(e => ({
        ok: false,
        message: e.response ? e.response.data.message || 'Não foi possível obter os dados cadastrais' : ''
      }))
  },
  updatePassword: async (old_password, password, password_confirmation) => {
    return api.put('/auth/update-password', {
      old_password,
      password,
      password_confirmation
    }).then(r => ({
        ok: true,
        message: r.data.message,
      }))
      .catch(e => ({
        ok: false,
        message: e.response ? e.response.data.message || 'Não foi possível obter os dados cadastrais' : ''
      }))
  },
  passwordRecoveryEmail: async (email) => {
    return api.post('/auth/password-recovery-email', { email }).then(r => ({
        ok: true,
        message: r.data.message,
      }))
      .catch(e => ({
        ok: false,
        message: e.response ? e.response.data.message || 'Não foi possível enviar o código de verificação' : ''
      }))
  },
  passwordRecoveryCheckCode: async (email, code) => {
    return api.post('/auth/password-recovery-check-code', { email, code }).then(r => ({
        ok: true,
        message: r.data.message,
        token: r.data.token,
        user: r.data.user,
      }))
      .catch(e => ({
        ok: false,
        message: e.response ? e.response.data.message || 'Não foi possível enviar o código de verificação' : ''
      }))
  },
  passwordRecoveryReset: async (token, email, password, password_confirmation, login = true) => {
    return api.post('/auth/password-recovery-reset', {
      token,
      email,
      password,
      password_confirmation,
      login
    }).then(r => ({
        ok: true,
        message: r.data.message,
        user: r.data.user,
        token: r.data.token,
        settings: r.data.settings,
      }))
      .catch(e => ({
        ok: false,
        message: e.response ? e.response.data.message || 'Não foi possível recuperar sua senha' : ''
      }))
  },
  profileUpdate: async ({ phone, email, username }) => {
    return api.put('/user', {
      phone,
      email,
      username,
    }).then(r => ({
        ok: true,
        message: r.data.message,
        user: r.data.user,
      }))
      .catch(e => ({
        ok: false,
        message: e.response ? e.response.data.message || 'Não foi possível atualizar seus dados' : ''
      }))
  },
}

export default Auth
