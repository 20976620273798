<template>
  <header>
    <slot />
  </header>
</template>

<script>
export default {
  name: 'TopBar',
}
</script>

<style scoped>
header {
  background: #FFFFFF;
  box-shadow: 0 0 10px #999999;
  margin: 0;
  padding: 4px 8px;
  min-height: 40px;
}
</style>
