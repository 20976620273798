<template>
  <div class="spacer"></div>
</template>

<script>
export default {
  name: 'Spacer',
}
</script>

<style scoped>
  .spacer {
    display: block;
    margin: 16px 0;
  }
</style>
