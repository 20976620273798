<template>
  <div id="login-container">
    <top-bar>
      <a href="https://pede.ai">
        <img src="@/assets/img/logo.png" alt="pede.ai - Delivery de tudo" id="logo" />
      </a>
    </top-bar>

    <div class="container">
      <div>
        <section class="media-flex-disable">
          <div>
            <h3 class="title">{{ title }}</h3>

            <div class="login-form-container">
              <LoginForm v-on:success="onSuccess" v-on:error="onError" v-on:forgot="onForgot" v-show="form == 'login'">
                <template v-slot:start>
                  <button-back href="/" />
                </template>
              </LoginForm>

              <ResetPasswordForm
                v-on:success="onSuccess"
                v-on:error="onError"
                v-on:giveup="onForgotGiveUp"
                v-on:updatetitle="onTitleUpdated"
                v-show="form == 'forgot'"
              ></ResetPasswordForm>
            </div>
          </div>
          <div class="text-center">
            <div class="char-container">
              <img class="char" src="@/assets/img/char.png" alt="pede.ai - Delivery de tudo">
              <img class="burger" src="@/assets/img/burger.png" alt="pede.ai - Delivery de tudo">
              <img class="donut" src="@/assets/img/donut.png" alt="pede.ai - Delivery de tudo">
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/navigation/TopBar.vue'
import ButtonBack from '@/components/buttons/ButtonBack.vue'
import LoginForm from '@/components/forms/LoginForm.vue'
import ResetPasswordForm from '@/components/forms/ResetPasswordForm.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'Login',
  components: {
    TopBar,
    ButtonBack,
    LoginForm,
    ResetPasswordForm,
  },
  data: () => ({
    form: 'login',
    title: 'Painel do PDV',
  }),
  methods: {
    ...mapMutations([ 'setUser', 'setSettings' ]),
    onSuccess({ user, token, settings }) {
      this.setUser({ ...user, token })
      this.setSettings(settings)
      this.$router.push({ path: '/dashboard' })
    },
    onError({ message }) {
      this.$notify({
        message,
        type: 'error',
      })
    },
    onForgot() {
      this.form = 'forgot'
      this.title = 'Recuperação de senha'
    },
    onForgotGiveUp() {
      this.form = 'login'
      this.title = 'Painel do PDV'
    },
    onTitleUpdated(title) {
      this.title = title
    }
  }
}
</script>

<style>
#logo {
  width: 100px;
  padding: 8px;
}

#login-container {
  display: block;
  min-height: 100vh;
  position: relative;
  background: #FFFFFF;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 85vh;
  padding: 50px;
  padding-top: 40px;
  position: relative;
}

section {
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
}

section > div {
  flex: 1;
  flex-direction: row;
  padding: 16px;
  position: relative;
}

.login-form-container {
  max-width: 400px;
  margin: auto;
  margin-top: 36px;
}

.button-back {
  top: -150px;
  left: -10px;
}

.char-container {
  height: 500px;
  max-width: 100vw;
  overflow: hidden;
}

.char-container img {
  max-height: 100%;
  max-width: 100%;
  position: relative;
}

.char-container img.donut,
.char-container img.burger {
  left: 20px;
  position: absolute;
  top: 5px;
}
</style>
