<template>
  <div :class="className" :id="id">
    <div class="notification-container">
      <div class="notification-icon"><i :class="`fa fa-${icon}`"></i></div>
      <div class="notification-title" v-if="typeof title !== 'undefined' && title.length">{{ title }}</div>
      <div class="notification-text" v-if="typeof message !== 'undefined' && message.length">{{ message }}</div>
      <div class="notification-close-button" @click="dismiss">&times;</div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'Notification',
  props: ['title', 'message', 'type', 'id', 'timeout'],
  computed: {
    icon () {
      return {
        'info': 'info-circle',
        'error': 'exclamation-circle',
        'success': 'check-circle',
        'warning': 'exclamation-triangle',
      }[this.type || 'success']
    },
    className () {
      return [
        'notification',
        `notification-${this.type || 'success'}`,
        this.fading ? 'notification-fading' : ''
      ].join(' ')
    },
  },
  data: () => ({
    fading: false,
    top: 8,
    timing: null,
    timeLeft: 4500,
  }),
  mounted () {
    if (typeof this.timeout !==  'undefined') {
      this.timeLeft = this.timeout
    }

    this.resumeTimeout()
    document.getElementById(this.id).addEventListener('mouseenter', this.pauseTimeout)
    document.getElementById(this.id).addEventListener('mouseleave', this.resumeTimeout)
  },
  methods: {
    ...mapMutations([ 'removeNotification' ]),
    dismiss () {
      this.fading = true
      setTimeout(() => {
        this.removeNotification(this.id)
      }, 300)
    },
    checkLiveness () {
      this.timeLeft -= 1000
      this.timing = setTimeout(this.checkLiveness, 1000)
      if (this.timeLeft <= 0) {
        window.clearInterval(this.timing)
        this.dismiss()
      }
    },
    pauseTimeout () {
      window.clearInterval(this.timing)
    },
    resumeTimeout () {
      this.timing = setTimeout(this.checkLiveness, 1000)
    }
  }
}
</script>

<style scoped>
.notification {
  animation-name: to-left;
  animation-duration: .5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, .2);
  padding: 16px 45px;
  position: relative;
  margin: 8px 0;
  max-width: 60vw;
  right: 0;
  top: 0;
  width: 300px;
  transition: all .3s;
  z-index: 9999;
}

.notification-icon {
  position: absolute;
  top: 17px;
  font-size: 17px;
  left: 12px;
}

.notification-title {
  font-family: NunitoBold;
}

.notification-close-button {
  background: rgba(255, 255, 255, .5);
  /* border: 1px solid #ffffff; */
  border-radius: 100%;
  color: #ffffff;
  cursor: pointer;
  font-size: 15px;
  font-weight: bolder;
  margin-top: -10px;
  padding: 0 5px;
  position: absolute;
  right: 8px;
  /* top: 50%; */
  top: 25px;
}

.notification-fading {
  right: -300px;
  opacity: 0;
}

.notification-error {
  background: #c53030;
  color: #ffffff;
}

.notification-success {
  background: #00b333;
  color: #ffffff;
}

.notification-warning {
  background: #EBC600;
  color: #ffffff;
}

.notification-white {
  background: #fafafa;
  color: #333333;
}

.notification-info {
  background: #0099cc;
  color: #ffffff;
}

@keyframes to-left {
  from {
    right: -400px;
    opacity: 0;
  }

  to {
    right: 8px;
    opacity: 1;
  }
}
</style>
