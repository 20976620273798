<template>
  <div id="dashboard-container">
    <transition name="fade">
      <modal title-center title="Por favor, atualize sua senha" v-if="require_password_updating" v-on:dismiss="require_password_updating = false">
        <template v-slot:body>
          <UpdatePasswordForm v-on:success="onPasswordResetSuccess"/>
        </template>
      </modal>
    </transition>
    <transition name="fade">
      <modal title-center title="Por favor, atualize seus dados cadastrais" v-if="require_data_updating && !require_password_updating" v-on:dismiss="require_data_updating = false">
        <template v-slot:body>
          <ProfileForm v-on:success="onDataUpdatingSuccess" @cancel="require_data_updating = false"/>
        </template>
      </modal>
    </transition>
    <div id="ds-col-left" :class="{ 'showing': left_bar_showing }">
      <LeftBar />
      <div id="ds-col-left-backdrop" @click="toggleLeftBar"></div>
    </div>
    <div id="ds-col-right">
      <DashboardTopBar>
        <template v-slot:start>
          <div>
            <button class="btn btn-primary btn-toggle-leftbar" @click="toggleLeftBar"><i class="fa fa-bars"></i></button>
            <bread-crumb class="main-bread-crumb" :items="bread" />
          </div>
        </template>

        <template v-slot:end>
          <div>
            <a :href="DOCS_URL" class="color-primary" target="_blank">Docs <i class="fa fa-external-link-alt fa-sm"></i></a>
          </div>
          <div class="dropdown">
            <a href="#" class="dropdown-toggle color-primary">{{ user.social_reason.slice(0, 14) }}... <i class="fa fa-caret-down"></i></a>
            <div class="dropdown-menu">
              <ul>
                <li><router-link :to="{ name: 'Profile' }">Perfil</router-link></li>
                <li class="divider"></li>
                <li><a href="#" @click.prevent="logout">Sair</a></li>
              </ul>
            </div>
          </div>
        </template>
      </DashboardTopBar>

      <div id="dashboard-router-view-container">
        <bread-crumb class="secondary-bread-crumb" :items="bread" />
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardTopBar from '@/components/navigation/DashboardTopBar.vue'
import LeftBar from '@/components/navigation/LeftBar.vue'
import BreadCrumb from '@/components/navigation/BreadCrumb.vue'
import { mapGetters, mapMutations } from 'vuex'
import Modal from '@/components/general/Modal.vue'
import UpdatePasswordForm from '@/components/forms/UpdatePasswordForm.vue'
import ProfileForm from '@/components/forms/ProfileForm.vue'

export default {
  name: 'Dashboard',
  components: {
    LeftBar,
    DashboardTopBar,
    BreadCrumb,
    Modal,
    UpdatePasswordForm,
    ProfileForm,
  },
  data: () => ({
    bread: [],
    require_password_updating: false,
    require_data_updating: false,
  }),
  computed: {
    ...mapGetters({ user: 'getUser', left_bar_showing: 'getNavbarShowing' }),
    DOCS_URL () {
      return process.env.VUE_APP_DOCS_URL
    }
  },
  mounted() {
    this.bread = this.$route.meta.bread
    this.require_password_updating = this.user.require_password_updating
    this.require_data_updating = this.user.require_data_updating
  },
  methods: {
    ...mapMutations([ 'setUser', 'setNavbarShowing' ]),
    logout() {
      this.setUser({})
      this.$router.push({ path: '/' })
    },
    onPasswordResetError({ message }) {
      this.$notify({
        title: 'Ops..',
        message: message,
        type: 'error'
      })
    },
    onPasswordResetSuccess({ message }) {
      this.require_password_updating = false
      this.$notify({
        title: 'Tudo certo!',
        message: message,
        type: 'success'
      })
    },
    onDataUpdatingSuccess() {
      this.require_data_updating = false
    },
    toggleLeftBar () {
      this.setNavbarShowing(!this.left_bar_showing)
    }
  },
  watch: {
    '$route' (to, ) {
      this.bread = to.meta.bread
    }
  }
}
</script>

<style scoped>
#dashboard-container {
  display: flex;
  align-items: flex-start;
  min-height: 100vh;
  position: relative;
  background: #F1F2F4;
  padding: 8px;
}

#ds-col-left {
  flex: 0 0 250px;
}

#ds-col-right {
  flex-grow: 8;
}

#dashboard-router-view-container {
  margin: 8px;
}

#ds-col-left-backdrop {
  background: rgba(0, 0, 0, .4);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  display: none;
  transition: all .5s;
}

.secondary-bread-crumb {
  display: none;
}

.btn-toggle-leftbar {
  display: none;
}

@media screen and (max-width: 860px) {
  #dashboard-container {
    display: block;
  }

  #ds-col-left {
    position: fixed;
    z-index: 888;
    width: 80vw;
    left: -90vw;
    top: 0;
    transition: all .3s;
  }

  #ds-col-left.showing {
    left: 0;
  }

  #ds-col-left.showing #ds-col-left-backdrop {
    display: block;
  }

  .main-bread-crumb {
    display: none;
  }

  .secondary-bread-crumb {
    display: block;
  }

  .btn-toggle-leftbar {
    display: block;
    color: #ff3333;
    background: #ffffff;
    padding: 10px;
  }
}
</style>
