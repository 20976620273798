<template>
  <header id="ds-top-bar">
    <div class="text-left">
      <slot name="start"/>
    </div>
    <div class="text-right">
      <slot name="end"/>
    </div>
  </header>
</template>

<script>
export default {
  name: 'DashboardTopBar',
}
</script>

<style scoped>
#ds-top-bar {
  align-items: center;
  display: flex;
  background: #FFFFFF;
  box-shadow: 0 0 10px #CCCCCC;
  border-radius: 5px;
  padding: 4px 8px;
  margin-left: 8px;
  min-height: 40px;
}

#ds-top-bar > div {
  flex: 1;
  flex-direction: row;
}

#ds-top-bar .text-right > div {
  display: inline-block;
  margin: 0 16px;
}

@media screen and (max-width: 860px) {
  #ds-top-bar .text-right {
    flex-grow: 6;
  }
}
</style>
