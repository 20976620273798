<template>
  <div>
    <slot name="start"/>

    <form action="/" @submit.prevent="sendEmail" v-if="step == 'email'">
      <button-back href="#" v-on:click.native="handleBack" />
      <p class="text-justify">Enviaremos um e-mail com um código para que possa recuperar sua senha. Insira o e-mail cadastrado da conta:</p>
      <div class="form-group">
        <input type="email" required v-model="email" placeholder="e-mail@domain.com" name="pedeai_pdv_reset_password_email">
      </div>

      <div class="form-group">
        <button class="btn btn-primary btn-100" type="submit" id="btn-reset-password-email" :disabled="loading.email">
          <i class="fa fa-spin fa-spinner" v-if="loading.email"></i>
          <template v-else>Enviar código</template>
        </button>
      </div>
    </form>

    <form action="/" @submit.prevent="checkCode" v-if="step == 'code'">
      <button-back href="#" v-on:click.native="handleBack" />
      <p class="text-justify">Informe o código que recebeu no e-mail {{ email }} abaixo:</p>
      <div class="form-group">
        <input type="text" required v-model="code" placeholder="########" name="pedeai_pdv_reset_password_code">
      </div>

      <div class="form-group">
        <button class="btn btn-primary btn-100" type="submit" id="btn-reset-password-email" :disabled="loading.code">
          <i class="fa fa-spin fa-spinner" v-if="loading.code"></i>
          <template v-else>Verificar</template>
        </button>
      </div>
    </form>

    <form action="/" @submit.prevent="reset" v-if="step == 'reset'">
      <button-back href="#" v-on:click.native="handleBack" />
      <p><strong>{{ user.name }}</strong></p>
      <p class="text-justify">Quase lá! Agora defina uma nova senha para sua conta:</p>

      <div class="form-group">
        <input type="password" required v-model="password" placeholder="Nova senha" name="pedeai_pdv_reset_password">
      </div>

      <div class="form-group">
        <input type="password" required v-model="password_confirmation" placeholder="Confirmação da senha" name="pedeai_pdv_reset_password_confirmation">
      </div>

      <div class="form-group">
        <label for="pedeai_pdv_reset_password_enable_login">
          Fazer login ao redefinir <input type="checkbox" class="inline" v-model="enable_login" id="pedeai_pdv_reset_password_enable_login">
        </label>
      </div>

      <div class="form-group">
        <button class="btn btn-primary btn-100" type="submit" id="btn-reset-password-email" :disabled="loading.reset || password !== password_confirmation">
          <i class="fa fa-spin fa-spinner" v-if="loading.reset"></i>
          <template v-else>
            Redefinir senha <template v-if="enable_login">e entrar</template>
          </template>
        </button>
      </div>
    </form>

    <slot name="end"/>
  </div>
</template>

<script>
import Auth from '@/services/Auth'
import ButtonBack from '@/components/buttons/ButtonBack.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'UpdatePasswordForm',
  components: {
    ButtonBack,
  },
  data: () => ({
    code: '',
    token: '',
    email: '',
    password: '',
    password_confirmation: '',
    enable_login: false,
    step: 'email',
    user: {},
    loading: {
      email: false,
      code: false,
      reset: false,
    },
  }),
  methods: {
    ...mapMutations([ 'setUser', 'setSettings' ]),
    handleBack() {
      if (this.loading.email || this.loading.code || this.loading.reset) {
        return
      }

      if (this.step == 'email') {
        return this.$emit('giveup')
      }

      if (this.step == 'code') {
        this.step = 'email'
        return
      }

      if (this.step == 'reset') {
        this.step = 'code'
        return
      }
    },
    async sendEmail() {
      if (!this.email.length) {
        return this.$notify({
          title: 'Ops..',
          message: 'Insira um e-mail válido para continuar',
          type: 'warning',
        })
      }

      this.loading.email = true
      const result = await Auth.passwordRecoveryEmail(this.email)
      this.loading.email = false

      if (!result.ok) {
        return this.$notify({
          title: 'Ops..',
          message: result.message,
          type: 'error'
        })
      }

      this.$notify({
        title: 'Código enviado',
        message: result.message,
        type: 'success'
      })

      this.$emit('updatetitle', 'Código de confirmação')
      this.step = 'code'
    },
    async checkCode() {
      if (!this.code.length) {
        return this.$notify({
          title: 'Ops..',
          message: 'Insira um código válido para continuar',
          type: 'warning',
        })
      }

      this.loading.code = true
      const result = await Auth.passwordRecoveryCheckCode(this.email, this.code)
      this.loading.code = false

      if (!result.ok) {
        return this.$notify({
          title: 'Ops..',
          message: result.message,
          type: 'error'
        })
      }

      this.$notify({
        title: 'Código verificado',
        message: result.message,
        type: 'success'
      })

      this.$emit('updatetitle', 'Redefinição de senha')
      this.token = result.token
      this.user = result.user
      this.step = 'reset'
    },
    async reset() {
      if (this.password !== this.password_confirmation) {
        return this.$notify({
          title: 'Ops..',
          message: 'As senhas digitadas não conferem. Tente novamente',
          type: 'warning',
        })
      }

      this.loading.reset = true
      const result = await Auth.passwordRecoveryReset(
        this.token,
        this.email,
        this.password,
        this.password_confirmation,
        this.enable_login
      )
      this.loading.reset = false

      if (!result.ok) {
        return this.$notify({
          title: 'Ops..',
          message: result.message,
          type: 'error',
        })
      }

      this.$notify({
        title: 'Tudo certo!',
        message: result.message,
        type: 'success'
      })

      if (this.enable_login) {
        this.setUser({ ...result.user, token: result.token })
        this.setSettings(result.settings)
        this.$router.push({ path: '/dashboard' })
        return
      }

      this.step = 'email'
      this.handleBack()
    }
  }
}
</script>

<style scoped>
a, a:visited {
  text-align: center;
  color: #FF3333;
  font-family: NunitoBold;
}

#btn-login {
  width: 100%;
}
</style>
