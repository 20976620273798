// import { mapMutations } from "vuex"
import NotificationsContainer from './components/NotificationsContainer'
import store from '@/store'

export default {
  install(Vue) {
    Vue.VERSION = 'v1.0.0'

    Vue.mixin({
      components: {
        'notifications': NotificationsContainer,
      },
    })

    Vue.prototype.$notify = function ({ title, message, type }) {
      store.commit('addNotification', { title, message, type })
    }

    Vue.$notify = function ({ title, message, type }) {
      store.commit('addNotification', { title, message, type })
    }
  }
}
