<template>
  <div id="page-events">
    <div class="flex">
      <div class="flex-item" id="fi-events">
        <panel>
          <form action="/" @submit.prevent="getEvents">
            <div class="flex">
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-events-filter-start_date">Data inicial:</label>
                  <date-picker
                    id="i-f-events-filter-start_date"
                    v-model="filters.start_date"
                    format="DD/MM/YYYY"
                    type="date"
                  ></date-picker>
                </div>
              </div>
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-events-filter-end_date">Data final:</label>
                  <date-picker
                    id="i-f-events-filter-end_date"
                    v-model="filters.end_date"
                    format="DD/MM/YYYY"
                    type="date"
                  ></date-picker>
                </div>
              </div>
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-events-filter-order_id">ID do Pedido:</label>
                  <input id="i-f-events-filter-order_id" type="text" v-model="filters.order_id">
                </div>
              </div>
              <div class="flex-item"></div>
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-events-filter-merchant_id">&nbsp;</label><br>
                  <button class="btn btn-primary btn-100" v-bind:class="loading.events ? 'btn-disabled' : ''" type="submit" :disabled="loading.events">
                    <i class="fa fa-spin fa-spinner" v-if="loading.events"></i>
                    <template v-else>Filtrar</template>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </panel>

        <panel title="Eventos de Pedido">
          <div class="flex">
            <div class="flex-item">
              <div class="form-group">
                <label for="i-f-events-filter-per_page">Qtde.:</label>
                <select id="i-f-events-filter-per_page" v-model="filters.per_page" @change.prevent="getEvents()">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </div>

            <div class="flex-item">
              <div class="form-group">
                <label for="i-f-events-filter-merchant_id">Empresa:</label>
                <select id="i-f-events-filter-merchant_id" v-model="filters.merchant_id" @change.prevent="getEvents()">
                  <option value="">Todas as empresas</option>
                  <option v-for="m in merchants" v-bind:key="m.id" :value="m.id">{{ m.name }}</option>
                </select>
              </div>
            </div>

            <div class="flex-item"></div>
            <div class="flex-item"></div>
          </div>
          <hr>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Evento</th>
                  <th>Status</th>
                  <th>Pedido</th>
                  <th>Data</th>
                  <th>Webhoooks</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(e, index) in events" v-bind:key="e.id" class="text-center">
                  <td>{{ index + 1 + ((filters.page - 1) * filters.per_page) }}</td>
                  <td>{{ e.event }}</td>
                  <td>{{ e.status }}</td>
                  <td><a href="#" @click.prevent="filters.order_id = e.order_id; getEvents()">{{ e.order_id }}</a></td>
                  <td>{{ e.created_at }}</td>
                  <td>
                    <router-link :to="{ path: '/webhooks', query: { event_id: e.id } }" class="color-primary" target="_blank">Acessar <i class="fa fa-external-link-alt fa-sm"></i></router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <br>

          <button
            v-for="b in events_pages"
            v-bind:key="b"
            v-bind:class="b == filters.page ? 'btn-primary' : 'btn-default'"
            :disabled="b == filters.page && loading.events"
            class="btn btn-inline mr-8"
            @click="paginate(b)"
          >
            <i class="fa fa-spin fa-spinner" v-if="b == filters.page && loading.events"></i>
            <template v-else>{{ b }}</template>
          </button>
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import Event from '@/services/Event'
import Merchant from '@/services/Merchant'
import Panel from '@/components/general/Panel.vue'
import DatePicker from 'vue2-datepicker';

export default {
  name: 'Webhoooks',
  components: {
    Panel,
    DatePicker,
  },
  data: () => ({
    events: [],
    events_pages: 0,
    merchants: [],
    show: false,
    current: null,
    filters: {
      page: 1,
      per_page: 10,
      event_id: '',
      merchant_id: '',
      order_id: ''
    },
    loading: {
      events: false,
      merchants: false,
      filter: false
    }
  }),
  mounted() {
    this.getMerchants()
    this.getEvents()
  },
  methods: {
    copyKey() {
      navigator.permissions.query({ name: "clipboard-write"}).then(result => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.writeText(this.settings.key);
        }
      });
    },
    copyEndpoint() {
      navigator.permissions.query({ name: "clipboard-write"}).then(result => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.writeText(this.settings.endpoint);
        }
      });
    },
    async getEvents() {
      if (this.filters.page == 0) {
        this.filters.page = 1
      }

      this.loading.events = true
      const result = await Event.all(this.filters)
      this.loading.events = false

      if (!result.ok) {
        this.$notify({ title: 'Ops..', message: result.message, type: 'error' })
        return
      }

      this.events = result.events
      this.events_pages = result.pages
      if (this.filters.page > this.events_pages && this.events_pages > 0) {
        this.paginate(this.events_pages)
      }
    },
    async getMerchants() {
      this.loading.merchants = true
      const result = await Merchant.all({ per_page: 50 })
      this.loading.merchants = false

      if (!result.ok) {
        this.$notify({ title: 'Ops..', message: result.message, type: 'error' })
        return
      }

      this.merchants = result.merchants
    },
    paginate(page) {
      if (page > this.events_pages) {
        return
      }

      this.filters.page = page
      this.getEvents()
    },
    webhooks(event) {
      this.$router.push({
        path: '/webhooks',
        query: {
          event_id: event.id,
        }
      })
    }
  }
}
</script>
