import api from './api'

const Stat = {
  all: async (data) => {
    return api.get('/stats', {
      params: { ...data }
    })
      .then(r => ({
        ok: true,
        stats: r.data.stats,
      }))
      .catch(e => ({
        ok: false,
        message: e.response ? e.response.data.message || 'Não foi possível obter as estatísticas' : ''
      }))
  },
}

export default Stat
