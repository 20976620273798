<template>
  <div id="page-dashboard">
    <div class="flex">
      <div class="flex-item">
        <panel title="Visão geral">
          <div class="flex">
            <div class="flex-item">
              <info-card
                :count="stats.count.merchants"
                color="green"
                icon="store"
              >
                <template v-slot:text>
                  <span v-if="stats.count.merchants > 1">Empresas <br>vinculadas</span>
                  <span v-else>Empresa <br>vinculada</span>
                </template>
              </info-card>
            </div>
            <div class="flex-item">
              <info-card
                :count="stats.count.events"
                color="blue"
                icon="list-alt"
                text="Eventos disparados"
                :loading="loading.stats"
              />
            </div>
            <div class="flex-item">
              <info-card
                :count="stats.count.webhooks"
                color="orange"
                icon="store"
                text="Disparos de Webhook"
                :loading="loading.stats"
              />
            </div>
            <div class="flex-item">
            </div>
          </div>
        </panel>
      </div>
    </div>

    <div class="flex">
      <div class="flex-item">
        <panel>
          <form action="/" @submit.prevent="getStats">
            <div class="flex">
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-stats-filter-start_date">Data Inicial:</label>
                  <date-picker
                    id="i-f-stats-filter-start_date"
                    v-model="filters.start_date"
                    format="DD/MM/YYYY"
                    type="date"
                  ></date-picker>
                </div>
              </div>
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-stats-filter-end-date">Data Final:</label>
                  <date-picker
                    id="i-f-stats-filter-end-date"
                    v-model="filters.end_date"
                    format="DD/MM/YYYY"
                    type="date"
                  ></date-picker>
                </div>
              </div>
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-stats-filter-merchant_id">Empresa:</label>
                  <select id="i-f-stats-filter-merchant_id" v-model="filters.merchant_id">
                    <option value="">Todas as empresas</option>
                    <option v-for="m in merchants" v-bind:key="m.id" :value="m.id">{{ m.name }}</option>
                  </select>
                </div>
              </div>
              <div class="flex-item">
                <div class="form-group">
                  <label>&nbsp;</label><br>
                  <button class="btn btn-primary btn-100" type="submit" :disabled="loading.stats">
                    <i class="fa fa-spin fa-spinner" v-if="loading.stats"></i>
                    <template v-else>Filtrar</template>
                  </button>
                </div>
              </div>
              <div class="flex-item"></div>
            </div>
          </form>
        </panel>

        <panel>
          <DispatchedEventsChart
            v-if="!loading.stats"
            :events="stats.events"
          />
          <ChartPlaceholder v-else />

          <Spacer />

          <DispatchedWebhooksChart
            v-if="!loading.stats"
            :webhooks="stats.webhooks"
          />
          <ChartPlaceholder v-else />
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import Panel from '@/components/general/Panel.vue'
import InfoCard from '@/components/general/InfoCard.vue'
import DispatchedEventsChart from '@/components/charts/DispatchedEventsChart.vue'
import DispatchedWebhooksChart from '@/components/charts/DispatchedWebhooksChart.vue'
import ChartPlaceholder from '@/components/charts/ChartPlaceholder.vue'
import Spacer from '@/components/general/Spacer.vue'
import DatePicker from 'vue2-datepicker';
import Stat from '@/services/Stat'
import Merchant from '@/services/Merchant'

export default {
  components: {
    Panel,
    InfoCard,
    DispatchedEventsChart,
    DispatchedWebhooksChart,
    DatePicker,
    ChartPlaceholder,
    Spacer,
  },
  name: 'Dashboard',
  data: () => ({
    stats: {
      count: {
        merchants: 0,
        events: 0,
        webhooks: 0,
      },
      events: [],
      webhooks: [],
    },
    loading: {
      stats: false,
      graphs: false,
      merchants: false,
    },
    filters: {
      start_date: null,
      end_date: null,
      merchant_id: null,
    },
    merchants: [],
  }),
  mounted() {
    this.getMerchants()
    this.getStats()
  },
  methods: {
    async getStats () {
      this.loading.stats = true
      const result = await Stat.all({
        start_date: this.filters.start_date,
        end_date: this.filters.end_date,
        merchant_id: this.filters.merchant_id
      })
      this.loading.stats = false

      if (!result.ok) {
        this.$notify({
          title: 'Ops..',
          message: result.message,
          type: 'error',
        })
        return
      }

      this.stats.count = result.stats.count
      this.stats.events = result.stats.events
      this.stats.webhooks = result.stats.webhooks
    },
    async getMerchants () {
      this.loading.merchants = true
      const result = await Merchant.all({ per_page: 50 })
      this.loading.merchants = false

      if (!result.ok) {
        return
      }

      this.merchants = result.merchants
    }
  },
}
</script>
