<template>
  <form action="/" @submit.prevent="reset">
    <slot name="start"/>

    <div class="form-group">
      <input type="password" required v-model="old_password" placeholder="Senha atual" name="pedeai_pdv_old_password">
    </div>

    <div class="form-group">
      <input type="password" required v-model="password" placeholder="Nova senha" name="pedeai_pdv_password">
    </div>

    <div class="form-group">
      <input type="password" required v-model="password_confirmation" placeholder="Confirme a nova senha" name="pedeai_pdv_password_confirmation">
    </div>

    <div class="form-group">
      <button class="btn btn-primary" v-bind:class="loading ? 'btn-disabled' : ''" type="submit" id="btn-login" :disabled="loading">
        <i class="fa fa-spin fa-spinner" v-if="loading"></i>
        <template v-else>Atualizar</template>
      </button>
    </div>

    <slot name="end"/>
  </form>
</template>

<script>
import Auth from '@/services/Auth'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'UpdatePasswordForm',
  data: () => ({
    old_password: '',
    password: '',
    password_confirmation: '',
    loading: false,
  }),
  computed: {
    ...mapGetters({ user: 'getUser' }),
  },
  methods: {
    ...mapMutations([ 'setUser' ]),
    async reset() {
      if (this.password !== this.password_confirmation) {
        this.$notify({
          title: 'Ops..',
          message: 'As senhas digitadas não conferem. Tente novamente',
          type: 'warning',
        })
        return
      }

      this.loading = true
      const result = await Auth.updatePassword(
        this.old_password,
        this.password,
        this.password_confirmation
      )
      this.loading = false

      if (!result.ok) {
        this.$notify({
          title: 'Ops..',
          message: result.message,
          type: 'error',
        })
        return
      }

      this.setUser({ ...this.user, require_password_updating: false })
      this.$emit('success', { ...result })
    }
  }
}
</script>

<style scoped>
a, a:visited {
  text-align: center;
  color: #FF3333;
  font-family: NunitoBold;
}

#btn-login {
  width: 100%;
}
</style>
