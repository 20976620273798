import api from './api'

const Event = {
  all: async (data) => {
    return api.get('/events', { params: { ...data, get_all: true } })
      .then(r => ({
        ok: true,
        pages: r.data.pages,
        events: r.data.events,
      }))
      .catch(e => ({
        ok: false,
        message: e.response ? e.response.data.message || 'Não foi possível obter os eventos' : ''
      }))
  },
  webhookCalls: async (data) => {
    return api.get('/webhook-calls', { params: { ...data } })
      .then(r => ({
        ok: true,
        pages: r.data.pages,
        calls: r.data.calls,
      }))
      .catch(e => ({
        ok: false,
        message: e.response ? e.response.data.message || 'Não foi possível obter os logs de chamada de webhook' : ''
      }))
  }
}

export default Event
