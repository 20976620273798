<template>
  <form action="/" @submit.prevent="login">
    <slot name="start"/>

    <div class="form-group">
      <input type="text" required v-model="username" placeholder="Nome de usuário" name="pedeai_pdv_username">
    </div>

    <div class="form-group">
      <input type="password" required v-model="password" placeholder="Senha" name="pedeai_pdv_password">
    </div>

    <div class="form-group">
      <button class="btn btn-primary" v-bind:class="loading ? 'btn-disabled' : ''" type="submit" id="btn-login" :disabled="loading">
        <i class="fa fa-spin fa-spinner" v-if="loading"></i>
        <template v-else>Entrar</template>
      </button>
    </div>

    <p class="text-center text-primary">
      <a href="#" @click.prevent="forgot">Esqueci minha senha</a>
    </p>

    <div class="form-group">
      <p class="transition-text text-center">Não possui cadastro ainda?</p>
    </div>

    <div class="form-group text-center">
      <a href="/">Cadastrar minha Software House <i class="fa fa-arrow-right"></i></a>
    </div>

    <p class="text-center">
      <a :href="DOCS_URL" target="_blank">Documentação <i class="fa fa-external-link-alt fa-sm"></i></a>
    </p>

    <slot name="end"/>
  </form>
</template>

<script>
import Auth from '@/services/Auth'

export default {
  name: 'LoginForm',
  data: () => ({
    username: '',
    password: '',
    loading: false,
  }),
  computed: {
    DOCS_URL() {
      return process.env.VUE_APP_DOCS_URL
    }
  },
  methods: {
    async login() {
      this.loading = true
      const result = await Auth.login({
        username: this.username,
        password: this.password
      })
      this.loading = false
      this.$emit(result.ok ? 'success' : 'error', { ...result })
    },
    forgot() {
      this.$emit('forgot')
    }
  }
}
</script>

<style scoped>
a, a:visited {
  text-align: center;
  color: #FF3333;
  font-family: NunitoBold;
}

#btn-login {
  width: 100%;
}
</style>
