<template>
  <div class="chart-placeholder" :style="style">
    <div class="chart-placeholder-loading-indicator"></div>
  </div>
</template>

<script>
export default {
  name: 'ChartPlaceholder',
  props: ['height'],
  computed: {
    style () {
      const height = typeof this.height === 'undefined' ? '300' : `${this.height}`;
      return `
        height: ${height}px
      `;
    }
  }
}
</script>

<style scoped>
  .chart-placeholder {
    background: #eee;
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .chart-placeholder-loading-indicator {
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: red;
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: .05;
    position: absolute;
    width: 40px;
  }
</style>
