<template>
  <div id="page-settings">
    <div class="flex">
      <div class="flex-item" id="fi-settings">
        <panel title="Configurações">
          <div class="flex">
            <div class="flex-item">
              <span>Chave de API</span>
              <br>
              <input type="hidden" v-model="settings.key" id="input-settings-key">
              <template v-if="showingKey">
                <code id="code-api-key">{{ settings.key }}</code>
              </template>
              <small class="color-gray" v-else>{{ settings.key.slice(0, 28) }}...</small>
              <button class="color-primary pointer" @click="copyKey"><i class="fa fa-copy"></i></button>
              <button class="color-primary pointer" @click="toggleKey"><i class="fa fa-eye"></i></button>
            </div>
            <div class="flex-item">
              <span>Endpoint (v1)</span>
              <br>
              <small class="color-gray">{{ settings.endpoint }}</small>
              <button class="color-primary pointer" @click="copyEndpoint"><i class="fa fa-copy"></i></button>
            </div>
          </div>
        </panel>
      </div>

      <div class="flex-item"></div>
    </div>
  </div>
</template>

<style scoped>
  #code-api-key {
    max-width: 300px;
    word-wrap: break-word;
  }
</style>

<script>
import { mapGetters } from 'vuex';
import Panel from '@/components/general/Panel.vue'

export default {
  components: { Panel },
  name: 'Settings',
  data: () => ({
    showingKey: false,
  }),
  computed: {
    ...mapGetters({ user: 'getUser', settings: 'getSettings' })
  },
  methods: {
    copyKey() {
      navigator.permissions.query({ name: "clipboard-write" }).then(result => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.writeText(this.settings.key);
          this.$notify({
            message: 'Chave de api copiada',
          })
        } else {
          this.$notify({
            message: 'Não foi possível copiar a chave de api',
            type: 'error',
          })
        }
      });
    },
    copyEndpoint() {
      navigator.permissions.query({ name: "clipboard-write" }).then(result => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.writeText(this.settings.endpoint);
          this.$notify({
            message: 'Endereço do endpoint copiado',
          })
        } else {
          this.$notify({
            message: 'Não foi possível copiar o endereço de api',
            type: 'error',
          })
        }
      });
    },
    toggleKey () {
      this.showingKey = !this.showingKey
    }
  }
}
</script>
