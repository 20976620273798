import Vue from 'vue'
import App from './App.vue'
import './assets/css/app.css'
import './assets/css/form.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import router from './router'
import store from './store'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import Notification from '@/plugins/notification'
import VueMask from 'v-mask'

Vue.config.productionTip = false
Vue.use(Notification)
Vue.use(VueMask)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
