import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { v4 as uuid } from 'uuid';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    settings: {},
    notifications: [],
    navbar_showing: false,
  },
  getters: {
    getUser: (state) => (state.user),
    getSettings: (state) => (state.settings),
    getNotifications: (state) => (state.notifications),
    getNavbarShowing: (state) => (state.navbar_showing),
  },
  mutations: {
    setUser: (state, payload) => {
      state.user = typeof payload == 'string' ? JSON.parse(payload) : payload
    },
    setSettings: (state, payload) => {
      state.settings = typeof payload == 'string' ? JSON.parse(payload) : payload
    },
    addNotification: (state, payload) => {
      state.notifications.push({ ...payload, id: uuid() })
    },
    removeNotification: (state, payload) => {
      state.notifications = state.notifications.filter(n => n.id != payload)
    },
    setNavbarShowing: (state, payload) => {
      state.navbar_showing = payload
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
