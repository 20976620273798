<template>
  <div id="page-webhooks">
    <transition name="fade">
      <modal title-center title="Detalhes de Webhook" v-if="show" v-on:dismiss="show = false">
        <template v-slot:body v-if="current">
          <p><strong>ID:</strong> {{ current._id }}</p>
          <p><strong>Pedido:</strong> {{ current.order_id }}</p>
          <p><strong>Evento:</strong> {{ current.event_id }}</p>
          <p><strong>Empresa:</strong> {{ current.merchant_id }}</p>
          <p><strong>URL:</strong> {{ current.url }}</p>
          <p><strong>Data:</strong> {{ current.called_at }}</p>
          <p>
            <strong>Corpo da requisição:</strong><br>
            <code>{{ current.data }}</code>
          </p>
          <p><strong>Status da resposta:</strong> {{ current.response_status }}</p>
          <p>
            <strong>Resposta:</strong><br>
            <code>{{ current.response }}</code>
          </p>
        </template>
      </modal>
    </transition>
    <div class="flex">
      <div class="flex-item" id="fi-webhooks">
        <panel>
          <form action="/" @submit.prevent="getWebhooks">
            <div class="flex">
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-webhooks-filter-start_date">Data inicial:</label>
                  <date-picker
                    id="i-f-webhooks-filter-start_date"
                    v-model="filters.start_date"
                    format="DD/MM/YYYY"
                    type="date"
                  ></date-picker>
                </div>
              </div>
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-webhooks-filter-end_date">Data final:</label>
                  <date-picker
                    id="i-f-webhooks-filter-end_date"
                    v-model="filters.end_date"
                    format="DD/MM/YYYY"
                    type="date"
                  ></date-picker>
                </div>
              </div>
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-webhooks-filter-order_id">ID do Pedido:</label>
                  <input id="i-f-webhooks-filter-order_id" type="text" v-model="filters.order_id">
                </div>
              </div>
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-webhooks-filter-event_id">ID do Evento:</label>
                  <input id="i-f-webhooks-filter-event_id" type="text" v-model="filters.event_id">
                </div>
              </div>
              <div class="flex-item">
                <div class="form-group">
                  <label for="i-f-webhooks-filter-merchant_id">&nbsp;</label><br>
                  <button class="btn btn-primary btn-100" v-bind:class="loading.webhooks ? 'btn-disabled' : ''" type="submit" :disabled="loading.webhooks">
                    <i class="fa fa-spin fa-spinner" v-if="loading.webhooks"></i>
                    <template v-else>Filtrar</template>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </panel>

        <panel title="Chamadas de Webhook">
          <div class="flex">
            <div class="flex-item">
              <div class="form-group">
                <label for="i-f-webhooks-filter-per_page">Qtde.:</label>
                <select id="i-f-webhooks-filter-per_page" v-model="filters.per_page" @change.prevent="getWebhooks()">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </div>
            <div class="flex-item">
              <div class="form-group">
                <label for="i-f-webhooks-filter-merchant_id">Empresa:</label>
                <select id="i-f-webhooks-filter-merchant_id" v-model="filters.merchant_id" @change.prevent="getWebhooks()">
                  <option value="">Todas as empresas</option>
                  <option v-for="m in merchants" v-bind:key="m.id" :value="m.id">{{ m.name }}</option>
                </select>
              </div>
            </div>
            <div class="flex-item"></div>
            <div class="flex-item"></div>
            <div class="flex-item"></div>
          </div>
          <hr />
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Status</th>
                  <th>Pedido</th>
                  <th>URL</th>
                  <th>Data</th>
                  <th>Ação</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(w, index) in webhooks" v-bind:key="w._id">
                  <td>{{ index + 1 + ((filters.page - 1) * filters.per_page) }}</td>
                  <td>{{ w.response_status }}</td>
                  <td><a href="#" @click.prevent="filters.order_id = w.order_id; getWebhooks()">{{ w.order_id }}</a></td>
                  <td>{{ w.url }}</td>
                  <td>{{ w.called_at }}</td>
                  <td>
                    <a href="#" @click.prevent="details(w)" class="btn btn-default">
                      <i class="fa fa-eye"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <br>

          <button
            v-for="b in webhooks_pages"
            v-bind:key="b"
            v-bind:class="b == filters.page ? 'btn-primary' : 'btn-default'"
            :disabled="b == filters.page && loading.webhooks"
            class="btn btn-inline mr-8"
            @click="paginate(b)"
          >
            <i class="fa fa-spin fa-spinner" v-if="b == filters.page && loading.webhooks"></i>
            <template v-else>{{ b }}</template>
          </button>
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import Event from '@/services/Event'
import Merchant from '@/services/Merchant'
import Panel from '@/components/general/Panel.vue'
import Modal from '@/components/general/Modal.vue'
import DatePicker from 'vue2-datepicker';

export default {
  name: 'Webhoooks',
  components: {
    Panel,
    Modal,
    DatePicker,
  },
  data: () => ({
    webhooks: [],
    webhooks_pages: 0,
    merchants: [],
    show: false,
    current: null,
    filters: {
      page: 1,
      per_page: 10,
      event_id: '',
      merchant_id: '',
      order_id: '',
      start_date: null,
      end_date:null,
    },
    loading: {
      webhooks: false,
      merchants: false,
      filter: false
    }
  }),
  mounted() {
    this.getMerchants()
    if (this.$route.query.event_id) {
      this.filters.event_id = this.$route.query.event_id
    }
    this.getWebhooks()
  },
  methods: {
    copyKey() {
      navigator.permissions.query({ name: "clipboard-write"}).then(result => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.writeText(this.settings.key);
        }
      });
    },
    copyEndpoint() {
      navigator.permissions.query({ name: "clipboard-write"}).then(result => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.writeText(this.settings.endpoint);
        }
      });
    },
    async getWebhooks() {
      if (this.filters.page == 0) {
        this.filters.page = 1
      }

      this.loading.webhooks = true
      const result = await Event.webhookCalls(this.filters)
      this.loading.webhooks = false

      if (!result.ok) {
        this.$notify({ title: 'Ops..', message: result.message, type: 'error' })
        return
      }

      this.webhooks = result.calls
      this.webhooks_pages = result.pages

      if (this.filters.page > this.webhooks_pages && this.webhooks_pages > 0) {
        this.paginate(this.webhooks_pages)
      }
    },
    async getMerchants() {
      this.loading.merchants = true
      const result = await Merchant.all({ per_page: 50 })
      this.loading.merchants = false

      if (!result.ok) {
        this.$notify({ title: 'Ops..', message: result.message, type: 'error' })
        return
      }

      this.merchants = result.merchants
    },
    paginate(page) {
      if (page > this.webhooks_pages) {
        return
      }

      this.filters.page = page
      this.getWebhooks()
    },
    details(webhook) {
      this.current = webhook
      this.show = true
    }
  }
}
</script>
