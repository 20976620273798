import store from '@/store'

export default function (to, from, next) {
  store.commit('setNavbarShowing', false)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.getUser && Object.keys(store.getters.getUser).length) {
      next();
    } else {
      next({
        path: "/",
      });
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (store.getters.getUser && Object.keys(store.getters.getUser).length) {
      next({
        path: "/dashboard",
      });
    } else {
      next();
    }
  } else {
    next();
  }
}
