import api from './api'

const Merchant = {
  all: async (data) => {
    return api.get('/merchants', { params: { ...data } })
      .then(r => ({
        ok: true,
        merchants: r.data.merchants,
      }))
      .catch(e => ({
        ok: false,
        message: e.response ? e.response.data.message || 'Não foi possível obter a lista de empresas' : ''
      }))
  }
}

export default Merchant
