<template>
  <div class="p-panel">
    <h4 class="p-panel-title" v-if="title">{{ title }}</h4>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: ['title']
}
</script>

<style scoped>
.p-panel {
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 0 5px #DDDDDD;
  font-size: 11pt;
  font-family: Nunito;
  margin-bottom: 12px;
  padding: 16px;
}

.p-panel-title {
  font-family: Nunito;
  font-size: 14pt;
  text-align: left;
  margin: 16px 0;
  font-weight: normal;
}
</style>
