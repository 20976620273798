<template>
  <div id="ntsctn-s2210j00pP-e-p22143">
    <Notification
      v-for="n in notifications"
      :key="n.id"
      :id="n.id"
      :title="n.title"
      :message="n.message"
      :type="n.type"
      :timeout="n.timout"
    />
  </div>
</template>

<style scoped>
  #ntsctn-s2210j00pP-e-p22143 {
    position: fixed;
    right: 8px;
    top: 8px;
    z-index: 9999;
    transition: all .3s;
  }
</style>

<script>
import { mapGetters } from 'vuex'
import Notification from './Notification'
export default {
  name: 'NotificationsContainer',
  components: {
    Notification,
  },
  computed: {
    ...mapGetters({ notifications: 'getNotifications' }),
  },
}
</script>
